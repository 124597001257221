import { styled } from "@mui/system";
import { TextField, Select, MenuItem, InputLabel } from "@mui/material";

export const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 20,
    backgroundColor: "#FFEBE",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  textField: {
    marginBottom: 8,
    marginTop: 8,
  },
  selectField: {
    minWidth: 200,
    marginBottom: 8,
    marginTop: 8,
  },
  selectAndPathContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export const StyledTextField = styled(TextField)({
  marginLeft: 8,
  marginRight: 8,
  marginBottom: 16,
  width: 200,
  "& .MuiInputLabel-root": {
    color: "var(--text-color)",
  },
  "& .MuiInputBase-root": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
  "& .MuiInputBase-input": {
    color: "var(--text-color)",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
});


export const StyledSelect = styled(Select)({
  marginLeft: 8,
  marginRight: 8,
  marginBottom: 16,
  width: 200,
  "& 	.MuiSelect-outlined": {
    color: "var(--text-color)",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px !important",
    borderColor: "var(--border-color) !important",
  },
  "& .MuiInputLabel-root": {
    color: "var(--text-color)",
  },
  "& .MuiInputLabel-outlined": {
    color: "var(--text-color)",
  },
});

export const StyledInputLabel = styled(InputLabel)({
  color: "var(--text-color)",
  "&.Mui-focused": {
    color: "var(--text-color)",
  },
});





export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  // ...StyledTextField,
  // "&.MuiListItem-root.Mui-selected": {
  //   backgroundColor: theme.palette.primary.main,
  //   color: "#EDF1F3",
  //   "&:hover": {
  //     backgroundColor: theme.palette.primary.dark,
  //   },
  // },
  // "&.MuiListItem-root.Mui-selected:hover": {
  //   backgroundColor: theme.palette.primary.dark,
  // },
  // "&:hover": {
  //   backgroundColor: theme.palette.primary.light,
  //   color: "#EDF1F3",
  // },
}));
