// DisclaimerModal.styles.js
import styled from "@emotion/styled";
import { DialogTitle, DialogActions, DialogContent } from "@mui/material";

export const DisclaimerContainer = styled(DialogContent)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.5",
  color: "var(--text-color)",
  backgroundColor: "var(--card-color)",
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: "var(--text-color)",
  backgroundColor: "var(--card-color)",
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  color: "var(--text-color)",
  backgroundColor: "var(--card-color)",
}));
