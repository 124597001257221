// DisclaimerModal.jsx
import React, { useState } from "react";
import {
  Dialog,
  Button,
} from "@mui/material";

import {
  DisclaimerContainer,
  StyledDialogTitle,
  StyledDialogActions,
} from "./DisclaimerModal.styles";


const DisclaimerModal = () => {
  const [open, setOpen] = useState(!localStorage.getItem("hideDisclaimer"));

  const handleClose = () => {
    localStorage.setItem("hideDisclaimer", true);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle>Disclaimer</StyledDialogTitle>
      <DisclaimerContainer>
        <p>
          The information and commands provided on this website are intended
          solely for educational purposes. Users are strongly advised to use the
          content responsibly and only on systems for which they have explicit
          permission. The author and website shall not be held responsible for
          any damages or unintended consequences resulting from the use or
          misuse of the provided content. By using this website, you acknowledge
          and agree to use the information at your own risk and for educational
          purposes only.
        </p>
      </DisclaimerContainer>
      <StyledDialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: "var(--icon-color)", // Add this line
          }}
        >
          I understand and agree
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default DisclaimerModal;
