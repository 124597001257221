import { styled } from "@mui/system";
import { TextField } from "@mui/material";

export const StyledNavigationBar = styled("div")({
  width: "300px",
  marginTop: "5px",
});

export const StyledList = styled("ul")({
  listStyleType: "none",
  padding: 0,
});

export const StyledListItem = styled("li")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "var(--nav-hover)",
    color: "#ffffff",
  },
});

export const StyledSubListItem = styled("li")({
  display: "flex", // Add display: flex
  alignItems: "center", // Add alignItems: center
  justifyContent: "flex-start", // Align items to the left
  paddingLeft: "32px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "var(--nav-hover)",
    color: "#ffffff",
  },
});

export const SearchTextField = styled(TextField)({
  marginTop: "15px",
  "& .MuiInputBase-input": {
    color: "var(--text-color)",
  },
  "& .MuiSvgIcon-root": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--border-color)",
    },
    "&:hover fieldset": {
      borderColor: "var(--border-color)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-color)",
    },
  },
});
