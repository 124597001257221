import React from "react";
import { Box, Snackbar } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { API_BASE_URL } from "../../config";

import {
  StyledCard,
  StyledCardContent,
  CommandContainer,
  StyledDescription,
  StyledCommandListContainer,
  WrappedTypography,
  StyledIconsContainer,
  CommandText,
  CommandContentWrapper,
  StyledDescriptionCard,
  StyledToolDescription,
  StyledSearchField,
} from "./CommandList.styles";
import CommandCardIcons from "../CommandCardIcons/CommandCardIcons";


const CommandList = ({ commands, description }) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  


  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

    const filteredCommands = () => {
      return commands.filter((commandObj) =>
        commandObj.description.toLowerCase().includes(searchText.toLowerCase())
      );
    };


  const handleCopyWithModifiers = (commandObj) => {
    // const commandObj = commands[index];

    const processedCommand = commandObj.markdownMode
      ? commandObj.command
      : commandObj.codeMode
      ? commandObj.formattedCommand
      : commandObj.command;
    navigator.clipboard.writeText(processedCommand).then(
      () => {
        console.log("Text copied to clipboard");
        setSnackbarOpen(true);
      },
      (err) => {
        console.error("Failed to copy text to clipboard", err);
      }
    );
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <StyledCommandListContainer>
        <StyledDescriptionCard>
          <StyledCardContent>
            <StyledToolDescription variant="body2">
              {description}
            </StyledToolDescription>
            <StyledSearchField
              value={searchText}
              onChange={handleSearchTextChange}
              label="Search"
              variant="outlined"
              size="small"
            />
          </StyledCardContent>
        </StyledDescriptionCard>

        {filteredCommands().length > 0 ? (
          filteredCommands().map((commandObj, index) => (
            <StyledCard key={index}>
              <StyledCardContent>
                <CommandContainer>
                  <StyledIconsContainer>
                    <CommandCardIcons
                      handleCopy={() => handleCopyWithModifiers(commandObj)}
                      commandObj={commandObj}
                      cardKey={index}
                    />
                  </StyledIconsContainer>
                  <CommandContentWrapper>
                    <StyledDescription variant="body2">
                      {commandObj.description}
                    </StyledDescription>
                    <WrappedTypography component="pre">
                      <CommandCardContent
                        commandObj={commandObj}
                        cardKey={index}
                      />
                    </WrappedTypography>
                  </CommandContentWrapper>
                </CommandContainer>
              </StyledCardContent>
            </StyledCard>
          ))
        ) : (
          <StyledCard>
            <StyledCardContent>
              <WrappedTypography variant="body1" color="var(--text-color)">
                No commands found.
              </WrappedTypography>
            </StyledCardContent>
          </StyledCard>
        )}
      </StyledCommandListContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Command copied to clipboard"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Box>
  );
};
const CommandCardContent = ({ commandObj, cardKey }) => {
  const [markdownMode, setMarkdownMode] = React.useState(false);
  const [codeMode, setCodeMode] = React.useState(false);
  const [formattedCommand, setFormattedCommand] = React.useState(
    commandObj.command
  );

  const renderedCommand = () => {
    if (codeMode) {
      return (
        <SyntaxHighlighter
          language={commandObj.language}
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
          style={monokai}
          wrapLines={true}
          showLineNumbers={true}
          customStyle={{
            maxHeight: "600px", 
            overflow: "auto", 
            borderRadius: "15px",
            padding: "20px",
           
          }}
        >
          {formattedCommand}
        </SyntaxHighlighter>
      );
    } else if (markdownMode) {
      return <ReactMarkdown>{commandObj.command}</ReactMarkdown>;
    } else {
      return <>{commandObj.command}</>;
    }
  };

  const fetchFormattedCommand = async (command, language) => {
    try {
      const response = await fetch(`${API_BASE_URL}/formatCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ command, language }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.formattedCommand;
      } else {
        console.error("Failed to fetch formatted command");
        return command;
      }
    } catch (error) {
      console.error("Error fetching formatted command", error);
      return command;
    }
  };

  React.useEffect(() => {
    const handleFormatChange = (event) => {
      if (event.detail.key === cardKey) {
        setMarkdownMode(event.detail.markdownMode);
        setCodeMode(event.detail.codeMode);

        commandObj.markdownMode = event.detail.markdownMode;
        commandObj.codeMode = event.detail.codeMode;
      }
    };

    window.addEventListener("formatChange", handleFormatChange);

    return () => {
      window.removeEventListener("formatChange", handleFormatChange);
    };
  }, [cardKey, commandObj]);

  React.useEffect(() => {
    if (codeMode) {
      fetchFormattedCommand(commandObj.command, commandObj.language).then(
        (formattedCmd) => {
           commandObj.formattedCommand = formattedCmd;
           setFormattedCommand(formattedCmd);
        }
      );
    }
  }, [codeMode, commandObj]);

  return (
    <CommandText>
      {/* <CardContentWrapper> */}
      {""}
        {renderedCommand()}
        {/* </CardContentWrapper> */}
    </CommandText>
  );
};


export default CommandList;
