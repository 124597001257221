import { styled } from "@mui/system";
import { Typography, Card, CardContent, TextField } from "@mui/material";

export const CommandContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "left",
  color: "var(--text-color)",
  
});

export const StyledDescription = styled(Typography)({
  color: "var(--desc-text-color)",
  textAlign: "left",
  paddingRight: "60px",
  
});

export const StyledCommandListContainer = styled("div")(({ theme }) => ({
  maxHeight: "620px",
  overflowY: "auto",
  padding: "4px",

  "&::-webkit-scrollbar": {
    width: "4px",
    backgroundColor: "var(--white-color)",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: "var(--card-color)",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "8px",
    backgroundColor: "var(--white-color)",
  },
  scrollbarColor: "var(--card-color) var(--white-color)",
  scrollbarWidth: "thin",
}));

export const StyledCard = styled(Card)({
  background: "var(--card-color)",
  borderRadius: "15px",
  padding: "8px 16px",
  marginBottom: "8px",
  width: "100%",
  maxWidth: "700px",
  minWidth: "700px",
  wordWrap: "break-word",
});

export const StyledCardContent = styled(CardContent)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
});

export const StyledIconsContainer = styled("div")({
  position: "absolute",
  top: "8px",
  right: "8px",
});

export const WrappedTypography = styled(Typography)({
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  maxWidth: "100%",
  overflow: "auto",
});

export const CommandText = styled("div")(({ theme }) => ({
  textAlign: "left",
  paddingTop: "15px",
  overflowWrap: "break-word",

}));

export const CardContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "left",
  overflow: "auto",
});


export const CommandContentWrapper = styled("div")({
  flexGrow: 1,
  marginRight: "48px",
  overflowWrap: "break-word",
  overflow: "auto",
});

export const StyledDescriptionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  borderColor: "var(--border-color)",
  borderStyle: "solid",
  borderWidth: "2px",
  backgroundColor: "var(--card-color)",
  color: "var(--text-color)",
  borderRadius: "8px",
  position: "sticky",
  top: 0,
  zIndex: 100,
  maxWidth: "700px",
  minWidth: "700px",
}));

export const StyledToolDescription = styled(Typography)(({ theme }) => ({
  color: "var(--desc-text-color)",
  textAlign: "left",
  fontSize: theme.typography.pxToRem(20),
  minWidth: "200px", // Add this line to set a minimum width
  maxWidth: "700px", // Add this line to set a maximum width
  overflowWrap: "break-word", // Add this line to wrap the text
}));

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  width: "100%", 
  maxWidth: "200px",
  marginLeft: "10px",
  "& .MuiInputLabel-root": {
    color: "var(--text-color)",
  },
  "& .MuiInputBase-root": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
  "& .MuiInputBase-input": {
    color: "var(--text-color)",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
}));