import React from "react";
import { Tooltip } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CodeIcon from "@mui/icons-material/Code";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import { StyledIconButton } from "./CommandCardIcons.styles";

const CommandCardIcons = ({ handleCopy, commandObj, cardKey }) => {
  const handleMarkdownMode = () => {
    const event = new CustomEvent("formatChange", {
      detail: {
        key: cardKey,
        markdownMode: true,
        codeMode: false,
      },
    });
    window.dispatchEvent(event);
  };

  const handleCodeMode = () => {
    const event = new CustomEvent("formatChange", {
      detail: {
        key: cardKey,
        markdownMode: false,
        codeMode: true,
      },
    });
    window.dispatchEvent(event);
  };

  const handlePlainTextMode = () => {
    const event = new CustomEvent("formatChange", {
      detail: {
        key: cardKey,
        markdownMode: false,
        codeMode: false,
      },
    });
    window.dispatchEvent(event);
  };

  return (
    <>
      <Tooltip title="Copy" placement="top">
        <StyledIconButton
          edge="end"
          color="inherit"
          onClick={handleCopy}
          aria-label="copy"
        >
          <FileCopyIcon />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Plain Text" placement="top">
        <StyledIconButton
          edge="end"
          color="inherit"
          onClick={handlePlainTextMode}
          aria-label="text"
        >
          <TextFormatIcon />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Markdown" placement="top">
        <StyledIconButton
          edge="end"
          color="inherit"
          onClick={handleMarkdownMode}
          aria-label="markdown"
        >
          <FormatBoldIcon />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Code" placement="top">
        <StyledIconButton
          edge="end"
          color="inherit"
          onClick={handleCodeMode}
          aria-label="code"
        >
          <CodeIcon />
        </StyledIconButton>
      </Tooltip>
    </>
  );
};

export default CommandCardIcons;
