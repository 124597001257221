import React, { useState } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { StyledTitle, StyledButton } from "./AppBar.styles";
import AboutModal from "../AboutModal/AboutModal";

const Appbar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "var(--header-color)",
          color: "--var(--text-color)"
        }}
      >
        <Toolbar>
          <StyledTitle>Your CTF Assistant</StyledTitle>
          <StyledButton onClick={handleOpen}>About</StyledButton>
        </Toolbar>
      </AppBar>
      <AboutModal open={open} onClose={handleClose} />
    </>
  );
};

export default Appbar;
