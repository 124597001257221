// FeedbackForm.jsx

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Box, Typography, Tooltip } from "@mui/material";
import { Feedback as FeedbackIcon } from "@mui/icons-material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { API_BASE_URL } from "../../config";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";

import {
  FeedbackIconButton,
  FormContainer,
  modalBoxStyle,
  StyledTextField,
  StyledButton,
  FieldsWrapper,
  MessageFieldWrapper,
} from "./FeedbackForm.styles";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const FeedbackForm = () => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,

    
    onSubmit: (values, { resetForm }) => {
      // Send the form data to the server
      fetch(`${API_BASE_URL}/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => {
          if (res.ok) {
          setSnackbarOpen(true);
          setErrorMessage(null);
            resetForm();
            handleClose();
          } else {
             setErrorMessage("Error submitting feedback");
             setSnackbarOpen(true);
          }
        })
        .catch((err) => {
          setErrorMessage(`Error submitting feedback: ${err.message}`);
          setSnackbarOpen(true);
        });
    },
  });

  return (
    <>
      <FeedbackIconButton onClick={handleOpen}>
        <Tooltip title="Feedback and suggestions" placement="top">
          <FeedbackIcon sx={{ fontSize: 40 }} />
        </Tooltip>
      </FeedbackIconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <ClickAwayListener onClickAway={handleClose}>
            <FormContainer onSubmit={formik.handleSubmit}>
              <Typography variant="h6" gutterBottom>
                Support the growth of this website by offering your input and
                suggestions
              </Typography>
              <FieldsWrapper>
                <StyledTextField
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <StyledTextField
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FieldsWrapper>
              <MessageFieldWrapper>
                <StyledTextField
                  label="Message"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                  multiline
                  rows={6}
                />
              </MessageFieldWrapper>
              The E-mail address will be used to send you replies, if necessary,
              and updates.
              <StyledButton type="submit">Submit</StyledButton>
            </FormContainer>
          </ClickAwayListener>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {errorMessage ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Feedback successfully submitted!
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

export default FeedbackForm;
