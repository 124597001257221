import { styled } from "@mui/system";
import { IconButton, TextField, Button } from "@mui/material";

export const FeedbackIconButton = styled(IconButton)({
  position: "fixed",
  bottom: "24px",
  right: "24px",
  zIndex: 100,
  color: "var(--icon-color)",
});

export const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  color: "var(--text-color)",
  alignItems: "flex-start",
});

export const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "var(--card-color)",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: "15px",
  p: "16px",
  width: "600px",
};



export const StyledTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "var(--text-color)",
  },
  "& .MuiInputBase-root": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
  "& .MuiInputBase-input": {
    color: "var(--text-color)",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--text-color)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--border-color)",
  },
 "&.MuiTextField-root": {
   flex: "1 1 0", // Added to make name and email fields fill available space
  },
});




export const StyledButton = styled(Button)({
  backgroundColor: "var(--button-background-color)",
  color: "var(--button-text-color)",
  "&:hover": {
    backgroundColor: "var(--button-hover-background-color)",
  },
  "& .MuiButton-label": {
    textTransform: "capitalize",
  },
  borderColor: "var(--border-color)",
  border: "1px solid",
  borderRadius: "4px",
  marginTop: "16px",
  alignSelf: "center",
});


export const FieldsWrapper = styled("div")({
  display: "flex",
  gap: "16px",
  width: "100%",
  flexWrap: "wrap",
});


export const MessageFieldWrapper = styled("div")({
  width: "100%",
  flexBasis: "100%",
  display: "flex",
  alignItems: "stretch",
});