import React, { useState, useEffect } from "react";
import AppBar from "./components/AppBar/AppBar";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import InputFields from "./components/InputFields/InputFields";
import CommandList from "./components/CommandList/CommandList";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import DisclaimerModal from "./components/DisclaimerModal/DisclaimerModal"; // Add this line

import "./App.css";

// TODO: [X] Center the InputFields and CommandList in the page
// TODO: [X] Add default list based on Kali Linux and tryhackme parrot and maybe more. When a user types the wordlist name it will filter
// TODO: [X] Add a scroll bar to the commands if the list is too long
// TODO: [X] Make the Icons functional for example Convert text to markdown
// TODO: [X] Make a small navigation in each Card that will have a Markdown, code, text and copy icons when you hover over them it will have a small tooltip with explanation
// TODO: [X] When you hover over the tool change the cursor to a pointer 
// TODO: [X] When copying the command need to copy if after it was changed - Markdown button
// TODO: [X] Dynamically change the input fields based on the selected command. Change the layout to columns on the right side
// TODO: [X] Add description to the tools and section instead of the row input fields
// TODO: [X] Make the commands list searchable - for example for a privilege escalation


// TODO: [-] When I filter the command the copy icon is not copying the correct command
// TODO: [-] If an Port is present than in the file and directory commands add the port to the URL

// TODO: [-] Add multiple command type styles for example: Markdown for code snippets, Images for GUI tools (make them searchable as well)

// TODO: [-] Allow users to add their own commands and save them in the local storage, send the command to the server and save it in the database


const App = () => {
  const [tools, setTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [commands, setCommands] = useState([]);
  const [toolDescription, setToolDescription] = useState("");
  const [showInputFields, setShowInputFields] = useState(false);
  const [inputs, setInputs] = useState({});


const handleInputChange = (field, value) => {
  setInputs((prevState) => ({
    ...prevState,
    [field]: { ...prevState[field], value },
  }));
};

  useEffect(() => {
    if (selectedTool) {
      setInputs(
        selectedTool.inputs.reduce(
          (acc, input) => ({
            ...acc,
            [input.name]: { type: input.type, value: input.name },
          }),
          {}
        )
      );
    }
  }, [selectedTool]);

    useEffect(() => {
      if (selectedTool) {
        setCommands(
          selectedTool.commands.map((commandObj) => ({
            command: Object.keys(inputs).reduce(
              (acc, key) => acc.replace(`{{${key}}}`, inputs[key].value),
              commandObj.command
            ),
            description: commandObj.description,
            language: commandObj.language,
          }))
        );
      }
    }, [inputs, selectedTool]);



  const handleSearch = (searchText) => {

    const filteredCategories = tools.map((category) => {
      const filteredTools = category.tools.filter(
        (tool) =>
          tool.name.toLowerCase().includes(searchText.toLowerCase()) ||
          tool.tags.some((tag) =>
            tag.toLowerCase().includes(searchText.toLowerCase())
          )
      );
      return { ...category, tools: filteredTools };
    });
    setTools(filteredCategories);
  };

  const handleNavigationClick = () => {
    setShowInputFields(true);
  };

  return (
    <div className="App">
      <AppBar />
      <div className="content">
        <NavigationBar
          categories={tools}
          onCommandsFetched={setSelectedTool}
          onSearch={handleSearch}
          onClick={handleNavigationClick}
          onDescriptionChanged={setToolDescription}
        />
        <div className="main">
          {commands.length > 0 && (
            <CommandList commands={commands} description={toolDescription} />
          )}


          {showInputFields && (
            <div className="inputFieldsContainer">
            <InputFields
              inputs={inputs}
              handleInputChange={handleInputChange}
            />
            </div>
          )}
        </div>
      </div>
      <FeedbackForm />
      <DisclaimerModal />
    </div>
  );
};

export default App;
