import { styled } from "@mui/system";
import { Typography, Button } from "@mui/material";

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: "var(--text-color)",
  flexGrow: 1,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: "2rem",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: "var(--text-color)",
}));
