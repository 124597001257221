import React from "react";
import { Typography } from "@mui/material";
import {
  StyledModal,
  StyledModalContent,
  StyledProfilePhoto,
  StyledNameWrapper,
  StyledTypographyWrapper,
  StyledCoffeeImage,
  StyledImageContainer,
  StyledSocialIconsContainer,
} from "./AboutModal.styles";
import { API_BASE_URL } from "../../config";

import { IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";


const AboutModal = ({ open, onClose }) => {
  const profilePhotoURL = `${API_BASE_URL}/profile-photo`;

  return (
    <StyledModal open={open} onClose={onClose}>
      <StyledModalContent>
        <StyledImageContainer>
          <StyledProfilePhoto src={profilePhotoURL} alt="Profile photo" />
          <StyledCoffeeImage
            src="https://www.buymeacoffee.com/assets/img/guidelines/download-assets-sm-2.svg"
            alt="Buy me a coffee"
            onClick={() =>
              window.open("https://www.buymeacoffee.com/vitalys", "_blank")
            }
          />
          <StyledSocialIconsContainer>
            <IconButton
              color="primary"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/vitalysimonovich/",
                  "_blank"
                )
              }
            >
              <LinkedInIcon style={{ color: "#0A66C2" }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() =>
                window.open("https://twitter.com/vitalysimx", "_blank")
              }
            >
              <TwitterIcon style={{ color: "#1DA1F2" }} />
            </IconButton>
          </StyledSocialIconsContainer>
        </StyledImageContainer>

        <StyledTypographyWrapper>
          <StyledNameWrapper>
            <Typography variant="h6" component="h2">
              My name is Vitaly Simonovich
            </Typography>
          </StyledNameWrapper>
          <Typography>
            I am a security researcher, a lecturer, and a CTF player.
          </Typography>
          <Typography>
            I have created this website to help CTF and boot2root players to
            generate pentesting tool commands based on user-supplied input.
          </Typography>
        </StyledTypographyWrapper>
      </StyledModalContent>
    </StyledModal>
  );
};

export default AboutModal;
