import { styled } from "@mui/system";
import { IconButton } from "@mui/material";

export const IconsContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "8px",
});

export const StyledIconButton = styled(IconButton)({
  color: "var(--icon-color)",
});
