import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config";
import {
  useStyles,
  StyledTextField,
  StyledSelect,
  StyledMenuItem,
  StyledInputLabel,
} from "./InputFields.styles";
import { FormControl } from "@mui/material";

const InputFields = ({ inputs, handleInputChange }) => {
  const classes = useStyles();
  const [wordLists, setWordLists] = useState([]);
  const [showCustomPathInput, setShowCustomPathInput] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/wordLists`)
      .then((response) => response.json())
      .then((data) => setWordLists(data));
  }, []);

  const handleSelectChange = (event, key) => {
    if (event.target.value === "custom") {
      setShowCustomPathInput(true);
    } else {
      setShowCustomPathInput(false);
    }
    handleInputChange(key, event.target.value);
  };
const renderInputFields = () => {
  
  return Object.keys(inputs).map((key, index) => {
    if (inputs[key].type === "textField") {
      return (
        <StyledTextField
          key={`textField-${index}`} 
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          value={inputs[key].value || ""}
          onChange={(e) => handleInputChange(key, e.target.value)}
        />
      );
    } else if (inputs[key].type === "selectField") {
      return (
        <React.Fragment key={`selectField-${index}`}> 
          <FormControl variant="outlined" className={classes.selectField}>
            <StyledInputLabel id={`${key}-label`}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </StyledInputLabel>
            <StyledSelect
              labelId={`${key}-label`}
              value={inputs[key].value || ''}
              onChange={(e) => handleSelectChange(e, key)}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              variant="outlined"
            >
              {wordLists.map((wordList) => (
                <StyledMenuItem key={wordList.id} value={wordList.path}>
                  {wordList.path}
                </StyledMenuItem>
              ))}
              <StyledMenuItem value="custom">Custom Path</StyledMenuItem>
            </StyledSelect>
          </FormControl>
          {showCustomPathInput && (
            <StyledTextField
              label="Custom Path"
              value={inputs.wordlist.value}
              onChange={(e) => handleInputChange("wordlist", e.target.value)}
              className={classes.textField}
            />
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  });
};

  return <div className={classes.container}>{renderInputFields()}</div>;
};

export default InputFields;
