import { styled } from "@mui/system";
import { Modal } from "@mui/material";

export const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledModalContent = styled("div")(({ theme }) => ({
  backgroundColor: "var(--header-color)",
  borderRadius: "4px",
  padding: "2rem",
  minWidth: "300px",
  color: "var(--text-color)",
  display: "flex",
  lineHeight: "1.5",
}));

export const StyledProfilePhoto = styled("img")(({ theme }) => ({
  borderRadius: "50%", 
  width: "100px",
  height: "100px",
  marginRight: "2rem",
}));

export const StyledCoffeeImage = styled("img")(({ theme }) => ({
  cursor: "pointer",
  display: "block",
  width: "120px",
  height: "auto",
  marginTop: "1rem",
  marginRight: "2rem",
}));

export const StyledImageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center", 
  height: "150px", 
  marginRight: "2rem",
});

export const StyledNameWrapper = styled("div")({
  display: "inline-block",
  verticalAlign: "top",
});

export const StyledTypographyWrapper = styled("div")({ 
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}); 



export const StyledSocialIconsContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start", 
  justifyContent: "center",
  height: "150px",
  marginRight: "2rem",
});