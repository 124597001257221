import React, { useState, useEffect } from "react";
import { Box, Collapse, Typography } from "@mui/material";
import { ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import { API_BASE_URL } from "../../config";

import {
  StyledNavigationBar,
  StyledList,
  StyledListItem,
  StyledSubListItem,
  SearchTextField,
} from "./NavigationBar.styles";

const NavigationBar = ({
  onSearch,
  onCommandsFetched,
  onClick,
  onDescriptionChanged,
}) => {
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetch(`${API_BASE_URL}/categories`)
      .then((response) => response.json())
      .then((data) => setCategories(data));
  }, []);

  const handleClick = (index) => {
    if (searchText) {
      setExpanded(null);
    } else {
      setExpanded(expanded === index ? null : index);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    onSearch(event.target.value);
  };

  const getInputsByToolName = (toolName) => {
    for (const category of categories) {
      for (const tool of category.tools) {
        if (tool.name === toolName) {
          onDescriptionChanged(tool.description);
          return tool.inputs;
        }
      }
    }
    return [];
  };

  const fetchToolCommands = async (toolName) => {
    const response = await fetch(`${API_BASE_URL}/tools/${toolName}`);
    const tool = await response.json();
    onClick();
    onCommandsFetched({ ...tool, inputs: getInputsByToolName(toolName) || [] });
  };

  const filterTools = (tool) => {
    return (
      tool.name.toLowerCase().includes(searchText.toLowerCase()) ||
      tool.tags.some((tag) =>
        tag.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  return (
    <StyledNavigationBar>
      <Box>
        <SearchTextField
          fullWidth
          placeholder="Search"
          InputProps={{
            startAdornment: <Search />,
          }}
          onChange={handleSearchChange}
        />
        <StyledList>
          {categories.map((category, index) => {
            const filteredTools = category.tools.filter(filterTools);
            if (filteredTools.length === 0) {
              return null;
            }
            return (
              <React.Fragment key={index}>
                <StyledListItem button onClick={() => handleClick(index)}>
                  <Typography>{category.name}</Typography>
                  {expanded === index ? <ExpandLess /> : <ExpandMore />}
                </StyledListItem>
                <Collapse
                  in={expanded === index || !!searchText}
                  timeout="auto"
                  unmountOnExit
                >
                  <StyledList component="div" disablePadding>
                    {filteredTools.map((tool) => (
                      <StyledSubListItem
                        key={tool.name}
                        button
                        onClick={() => fetchToolCommands(tool.name)}
                      >
                        <Typography>{tool.name}</Typography>
                      </StyledSubListItem>
                    ))}
                  </StyledList>
                </Collapse>
              </React.Fragment>
            );
          })}
        </StyledList>
      </Box>
    </StyledNavigationBar>
  );
};

export default NavigationBar;
